<template>
  <div>
    <b-modal
      ref="modalDataRecusa"
      :title="$t('Deseja recusar esta angariação?')"
      :cancel-title="$t('Não')"
      cancel-variant="outline-secondary"
      size="lg"
      body-class=""
      :visible="false"
      :ok-only="false"
      :ok-title="$t('Sim')"
      ok-variant="primary"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      @ok="confirmSaveModal($event)"
      @cancel="closeModal"
    >
      <b-row>
        <b-col md="12">
          <b-form-group
            :label="$t('Observação do estado')"
          >
            <b-form-textarea
              v-model="txtObs"
              placeholder=""
              rows="4"
            />
          </b-form-group>
        </b-col>
      </b-row>

    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BRow, BCol, BFormGroup, BFormTextarea,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import useAppConfig from '@core/app-config/useAppConfig'
import { mixFormGeral } from '../../mixins'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  mixins: [mixFormGeral],
  props: {
  },
  data() {
    return {
      txtObs: '',
    }
  },
  computed: {
    ...mapGetters('listings_form', ['form_data']),
    isDark() {
      return this.skin.value === 'dark'
    },
  },
  methods: {
    showModal() {
      return new Promise((resolve, reject) => {
        this.$refs.modalDataRecusa.show()
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    hideModal() {
      this.$refs.modalDataRecusa.hide()
    },
    async confirmSaveModal(evt) {
      evt.preventDefault()
      this.resolvePromise(true)
    },
    async closeModal() {
      this.resolvePromise(false)
    },
  },
  setup() {
    const { skin } = useAppConfig()

    return {
      skin,
    }
  },
}
</script>
